<template>
  <div v-show="categories.length > 0" class="filter mb-40 text-blue-dark text-22 font-semibold">
    <ul class="!list-none flex !p-0 !m-0">
      <li><a class="active" href="#all" data-filter="all" @click="filter">All</a></li>
      <li class="ml-10" v-for="category in categories" :key="category.term_id">
        <a :href="'#' + category.slug" :data-filter="category.slug" @click="filter">
          {{ category.name }}
        </a>
      </li>
    </ul>
  </div>

	<div class="video-grid grid justify-center lg:grid-cols-2 gap-8">
    <!-- <article v-show="filterValue === 'all' || video.categories.includes(filterValue)" v-for="video in videos" :key="video.id" class="video" :class="video.popupmaker_class"> -->
    <article 
      v-show="filterValue === 'all' || video.categories.includes(filterValue)" 
      v-for="video in videos" :key="video.id" class="video"
      @click="updateModal"
      :data-url="video.url"
    >
      <div class="video-thumbnail">
        <img :src="video.thumb" :alt="video.title" />
        <div class="video-btn">
          <img src="/wp-content/uploads/2022/05/m_play_icon.svg" alt="" title="play_icon">
        </div>
      </div>
      <h2 class="text-24 lg:text-32 text-blue-dark font-semibold mt-30 font-sofia"> 
        {{ video.title }}
      </h2>
    </article>
  </div>
</template>

<script>
//libraries
import axios from 'axios';
import queryString from 'query-string';
import disableScroll from 'disable-scroll';

export default {

  data() {
    return {
      ajaxurl: ajaxurl, // Set globally by WordPress
      categories: [],
      videos: [],
      filterValue: 'all',
      modalOn: false,
      modal: '',
    };
  },


  watch: {
    modalOn(newVal) {
      newVal ? disableScroll.on() : disableScroll.off();
    },
  },

  methods: {
    //Filter videos by category (slug)
    filter(e) {
      this.filterValue = e.target.dataset.filter;
      document.querySelectorAll('.filter a').forEach(el => el.classList.remove('active'));
      e.target.classList.add('active');
    },

    updateModal(e) {
      console.log(e.currentTarget.dataset.url);
      this.modal.querySelector('.modal-content').innerHTML = `<iframe width="100%" style='aspect-ratio:16/9' src="https://www.youtube.com/embed/${this.extractYouTubeId(e.currentTarget.dataset.url)}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
      this.openModal();
    },

    openModal() {
      this.modal.classList.add('active');
      this.modal.style.top = `${window.scrollY}px`;
      this.modalOn = true;
    },

    closeModal() {
      this.modal.classList.remove('active');
      this.modalOn = false;
    },

    //Creates and appends a modal element to the page
    createModal() {
      this.modal = document.createElement('div');
      this.modal.classList.add('modal');
      this.modal.innerHTML = `<div class="modal-inner">
                                <span class="modal-close"></span>
                                <div class="modal-content"></div>
                              </div>`;

      this.modal.addEventListener('click', (event) => {
        if (!event.target.closest('.modal-content')) { // Check if the clicked element is not a child of .modal-content
          this.closeModal();
        }
      });

      document.body.appendChild(this.modal);
    },

    extractYouTubeId(url) {
      const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      let match = url.match(regExp);
      if (match && match[2].length == 11) {
        return match[2];
      }
    },

    //get video data from wordpress
    async getVideos() {
      const data = {
        action: 'get_videos',
      };

      try {
        const res = await axios.post(this.ajaxurl, data, {
          transformRequest: [
            function (data) {
              data = queryString.stringify(data);
              return data;
            },
          ],
        });

        this.videos = res.data;

      } catch (error) {
        console.log(error);
      }
    },

    //get video categories from wordpress
    async getVideoCategories() {
      const data = {
        action: 'get_video_categories',
      };

      try {
        const res = await axios.post(this.ajaxurl, data, {
          transformRequest: [
            function (data) {
              data = queryString.stringify(data);
              return data;
            },
          ],
        });

        this.categories = res.data;

      } catch (error) {
        console.log(error);
      }
    },
  },

  async created() {
    this.getVideos();
    this.getVideoCategories();
    this.createModal();
  },
};
</script>


